import $ from 'jquery';

class PartnerSelect {
  constructor(selector) {
    this.selector = selector
    this.init_select()
  }

  init_select() {
    $(this.selector).each(function () {
      const placeholder = $(this).data('placeholder')
      const url = $(this).data('url')
      $(this).select2({
        placeholder: placeholder,
        allowClear: true,
        ajax: {
          url: url,
          data: function (params) {
            var query = {
              q: params.term,
              page: params.page || 1
            }

            return query;
          }
        }
      })
    })
  }
}

export default PartnerSelect;