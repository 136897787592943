import $ from 'jquery';

class DynamicSelect {
  constructor(selector) {
    this.selector = selector
    this.init_select()
  }

  init_select() {
    $(this.selector).select2({
      tags: true,
    })
  }
}

export default DynamicSelect;