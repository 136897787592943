import $ from 'jquery';
import DashboardIndex from './dashboard';
import DefaultAzia from '../components/default_azia';
import { OffersNew } from './offers';
import { CouponsNew } from './coupons';
import {
  PartnersNew,
  PartnersIndex
} from './partners';

class Pages {
  constructor() {
    this.DashboardIndex = DashboardIndex

    this.add_offers()
    this.add_coupons()
    this.add_partners()
    this.init()
  }

  add_offers(){
    this.OffersNew = OffersNew
    this.OffersEdit = OffersNew
    this.OffersCreate = OffersNew
    this.OffersUpdate = OffersNew
  }

  add_coupons(){
    this.CouponsNew = CouponsNew
    this.CouponsEdit = CouponsNew
    this.CouponsCreate = CouponsNew
    this.CouponsUpdate = CouponsNew
  }

  add_partners(){
    this.PartnersNew = PartnersNew
    this.PartnersEdit = PartnersNew
    this.PartnersCreate = PartnersNew
    this.PartnersUpdate = PartnersNew
    this.PartnersIndex = PartnersIndex
  }

  init(){
    $(document).on('turbolinks:load', () => {
      const page = $('body').data('page')

      if (this.getPage(page)) {
        const module = this.getPage(page)
        new module()
      }

      new DefaultAzia() // run default azia js code
    })
  }

  getPage(module) {
    return this[module]
  }
}

export default Pages;