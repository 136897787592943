import $ from 'jquery';
import DynamicSelect from '../../components/dynamic_select';

export class PartnersNew {
  constructor() {
    this.categories_select = new DynamicSelect('#partner_category_list')
  }
}

export class PartnersIndex {
  constructor() {
  }
}
