import $ from 'jquery';
import Datepicker from '../../components/datepicker';
import PartnerSelect from '../../components/partner_select';

export class CouponsNew {
  constructor() {
    this.datepicker = new Datepicker()
    this.partner_select = new PartnerSelect('.partner-select')
  }
}
