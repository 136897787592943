// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("jquery")
require("@nathanvda/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('/vendor/azia/lib/bootstrap/js/bootstrap.bundle.min.js')
require('/vendor/azia/lib/jquery-ui/ui/widgets/datepicker.js');
require('/vendor/azia/lib/jquery-ui/ui/i18n/datepicker-ru.js');
require('/vendor/azia/lib/select2/js/select2.full.min.js')

import '/vendor/azia/lib/select2/css/select2.min.css';
import '/vendor/azia/lib/fontawesome-free/css/all.min.css';
import '/vendor/azia/lib/ionicons/css/ionicons.min.css';
import '/vendor/azia/lib/typicons.font/typicons.css';
import '/vendor/azia/lib/line-awesome/css/line-awesome.min.css';
import '/vendor/azia/css/azia.css';
import 'stylesheets/application.scss';

import Pages from '../pages/index';
window.pages = new Pages()