import $ from 'jquery';

class Datepicker {
  constructor() {
    $.datepicker.setDefaults($.datepicker.regional['ru']);

    $('.fc-datepicker').datepicker({
      showOtherMonths: true,
      selectOtherMonths: true
    });
  }
}

export default Datepicker;
